import React from 'react';

import { onboard, send } from '../functions'

//import component
import MapMain from './map/main.js'

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users:[],
      newProfile: null,
      historyProfile: [],
      message: [],
      showMap: false,
      pos: null,
      tutorial: 0,
    }

    this.input = React.createRef()

    this.msg = ''

  }

  componentDidMount() {
    //obnoard user and regist render function
    onboard({user:{lat:this.props.location.lat,lng:this.props.location.lng,name:this.props.start.name, avatar:this.props.start.avatar}, start: this.props.start},(err, order) => {
      if(order.type === 'onboard'){
        this.setState({users:order.data})
      } else if(order.type === 'update'){
        this.setState({users:order.data})
      } else if(order.type === 'newProfile'){
        this.setState({newProfile:order.data})
      } else if(order.type === 'historyProfile'){
        this.setState({historyProfile:order.data})
      } else if(order.type === 'chat'){
        let message = this.state.message
        message.push(order.data)
        this.setState({message})
      }
    })

    window.addEventListener('keydown', (e) => {
      if(e.code === 'Enter' && this.msg !== '' ) {
        this.sendText(this.msg)
        this.msg = ''
        this.input.current.value = ''
      }
    })
  }

  sendText(txt) {
    let msg = {type: 'text', content: txt}
    send(msg)
  }

  sendPos() {
    let lat = this.state.pos.lat()
    let lng = this.state.pos.lng()
    let msg = {type: 'pos', content: {lat: lat, lng: lng}}
    send(msg)
  }

  toggleMap() {
    let show = this.state.showMap
    this.setState({showMap:!show})
  }

  render() {
    return (
      <div>
        <style>{`
            .streetview {
              position: fixed !important;
              left: 0;
              top: 0;
              width: 100vw;
              height: 100vh;
              z-index: 1;
            }
            .map-wrapper {
              display: ${this.state.showMap?'block':'none'};
              z-index: 9999;
              position: fixed;
            }

            .map {
              position: fixed !important;
              left: 50px;
              bottom: 50px;
              width: calc(100vw - 100px);
              height: calc(100vh - 100px);
              z-index: 2;
            }

            .map-overlay {
              position: fixed;
              left: 0;
              top: 0;
              width: 100vw;
              height: 100vh;
              z-index: 1;
              background-color: rgba(0,0,0,.7);
            }

            .map-close {
              position: fixed;
              background-color: #000;
              color: #fff;
              top: 60px;
              right: 60px;
              width: 60px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 9;
              font-size: 36px;
              cursor: pointer;
              border-radius: 50%;
              font-family: tg;
            }

            .chat {
              position: fixed;
              z-index: 999;
              right: 20px;
              bottom: 60px;
            }

            .message {
              font-size: 20px;
              margin-bottom: 20px;
              text-align: right;
            }

            .sender {
              text-align: right;
              right: 100px;
              top: 8px;
              font-size: 12px;
              color: #fff;
            }

            .text {
              display:inline-block;
              border-radius: 40px;
              background-color: rgba(0,0,0,.5);
              padding: 16px 20px 16px;
              color: #fff;
              text-align: right;
              max-width: 400px;
            }

            .msg-pos {
              text-decoration: underline;
              cursor: pointer;
            }

            .thumbnail {
              width: 63px;
              height: 63px;
              vertical-align: top;
              border-radius: 50%;
              overflow: hidden;
              margin-left: 10px;
              background-color: #fff;
              display: inline-block;
            }

            .thumbnail img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .input {
              position: fixed;
              right: 140px;
              bottom: 20px;
              width: 280px;
              border-radius: 23px;
              height: 46px;
              z-index: 999;
              border: none;
              padding: 0 20px;
            }

            .input:focus {
              outline: none;
            }
            .send-btn {
              position: fixed;
              right: 20px;
              bottom: 20px;
              border-radius: 31px;
              z-index: 999;
              background-color: #fff;
              color: #000;
              cursor: pointer;
              font-family: tg;
              font-size: 36px;
              padding: 5px 15px;
            }

            a[href^="http://maps.google.com/maps"]{display:none !important}
            a[href^="https://maps.google.com/maps"]{display:none !important}

            .gmnoprint a, .gmnoprint span, .gm-style-cc {
                display:none;
            }

            .pos-control {
              position: fixed;
              left: 20px;
              bottom: 20px;
              z-index: 999;
            }

            .map-btn {
              padding: 5px 15px;
              background-color: #fff;
              color: #000;
              text-align: center;
              border-radius: 31px;
              display: inline-block;
              cursor: pointer;
              font-family: tg;
              font-size: 36px;
            }

            .sharepos-btn {
              padding: 5px 15px;
              background-color: #fff;
              color: #000;
              text-align: center;
              border-radius: 31px;
              display: inline-block;
              margin-left: 20px;
              cursor: pointer;
              font-family: tg;
              font-size: 36px;
            }

            .tutorial {
              position: fixed;
              top: 20px;
              left: 50vw;
              color: #fff;
              z-index: 99999;
              transform: translate(-50%);
            }

        `}</style>
        <MapMain
          apiKey={'AIzaSyCHcK7M7xDzidlWAPFYhJ2wMLW2fTsja5M'}
          users={this.state.users}
          start={this.props.start}
          location={this.props.location}
          newProfile={this.state.newProfile}
          historyProfile={this.state.historyProfile}
          onPositionChanged={(e) => this.setState({pos:e})}
          newPos={this.state.newPos}
          streetViewPanoramaOptions={{
            position:this.props.location,
            pov: { heading: 0, pitch: 0 },
            zoom: 1,
            //disable controls
            addressControl: false,
            fullscreenControl: false,
            zoomControl: false,
            scrollwheel: false,
            disableDefaultUI: true,}}
        />
        <div className='streetview'></div>
        <div className='map-wrapper'>
          <div className='map'></div>
          <div className='tutorial'>drag your pegman around to change position</div>
          <div className='map-overlay'></div>
          <div className='map-close' onClick={() => this.toggleMap()}>X</div>
        </div>
        <div className='chat'>
          {
            this.state.message.map((msg,index) => {
              let thumb = null
              this.state.historyProfile.forEach(p => {
                if(p.id === msg.id) {
                  thumb = p.data
                }
              })
              let profile
              if(thumb) {
                profile = React.createElement(
                  'img',
                  {
                    src: thumb,
                  },
                )
              }
              if(msg.message.type === 'text') {
                let name = msg.name || msg.id
                return <div className='message' key={index}>
                  <span className='text'>
                    <div className='sender'>{name}</div>
                    <div style={{textAlign:'left', display:'inline-block'}}>
                    {
                      msg.message.content
                    }
                    </div>
                  </span>
                  <div className='thumbnail'>
                    {profile?profile:''}
                  </div>
                  </div>
              } else {
                let name = msg.name || msg.id
                return <div className='message' key={index}>
                  <span className='text' onClick={() => this.setState({newPos:msg.message.content},() => {this.setState({newPos:null})})}>
                    <div className='sender'>{name}</div>
                    <div className='msg-pos' style={{textAlign:'left', display:'inline-block'}}>
                      {name} shared a position, Click to meet him/her
                    </div>
                  </span>
                  <img src={thumb} className='thumbnail' />
                  </div>
              }

            })
          }
        </div>
        <div className='pos-control'>
          <div className='map-btn' onClick={() => this.toggleMap()}>Map</div>
          <div className='sharepos-btn' onClick={() => this.sendPos()}>Share Position</div>
        </div>
        <div className='send'>
          <input className='input' ref={this.input} onChange={(e) => {this.msg = e.target.value}}/>
          <div className='send-btn' onClick={() => {
            if(this.msg !== '') {
              this.sendText(this.msg)
            }
            this.input.current.value = ''
            this.msg = ''
          }}>Send</div>
        </div>
      </div>
    )
  }
}

export default Map;
