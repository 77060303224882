import React from 'react'

class Naming extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: ''
    }
    this.nickname = ''
    this.step2 = React.createRef()
  }

  nextStep() {
    this.step2.current.classList.add('fadeout')
    setTimeout(() => this.props.getData(this.nickname),300)
  }

  render() {
    return(
      <div>
      <style>{`

        .naming {
          position: fixed;
          left: 50vw;
          top: 50vh;
          transition: .3s;
          animation: fadein .5s 1;
        }
        .fadeout{
          opacity: 0;
          top: 30vh;
        }
        @keyframes fadein {
          0%   { top: 70vh; opacity:0; }
          100% { top: 50vh; opacity:1; }
        }
        .relative {
          position: relative;
        }

        .naming .naming-wrapper {
          position: fixed;
          transform: translate(-50%, -50%);
          text-align: center;
        }

        .naming .name {
          display: block;
          border: none;
          border-bottom: 3px solid #000;
          font-size: 32px;
          padding: 20px 0;
          text-align: center;
          width: 500px;
          font-family: inherit !important;
          background-color: transparent;
        }
        .name:focus {
          outline: none;
        }

        .naming .title {
          font-size: 24px;
          position: fixed;
          left: 40px;
          top: 40px;
        }


        .naming .next {
          font-size: 24px;
          padding: 10px;
          position: fixed;
          right: 40px;
          bottom: 40px;
          border: 2px solid #000;
          display: inline-block;
          border-radius: 30px;
          cursor: pointer;
        }
        .naming .next:hover {
          background-color: #000;
          color: #fff;
        }

        .naming-wrapper .profile {
          display: block;
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 50%;
          overflow: hidden;
          margin: 0 auto 50px;
        }

      `}</style>
        <div className='naming' ref={this.step2}>
          <div className='title'>Whats your name?</div>
          <div className='naming-wrapper'>
            <img className='profile' src={this.props.profile} />
            <input className='name' placeholder='Name' onChange={e => this.nickname = e.target.value} />
          </div>
          <div className='next' onClick={() => this.nextStep()}>Next</div>
        </div>
      </div>
    )
  }
}

export default Naming
