import React from 'react'

class AvatarCreate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      avatar: {
        head: 1,
        body: 1,
        legs: 1,
        back: 1
      }
    }
    this.step1 = React.createRef()
    this.legs = React.createRef()
    this.body = React.createRef()
    this.head = React.createRef()
    this.back = React.createRef()
    this.trans = null
  }

  switch(e) {
    clearTimeout(this.trans)
    let avatar = this.state.avatar
    avatar[e]++
    switch(e) {
      case 'head':
        avatar[e] = avatar[e] > 10 ? 1 : avatar[e]
        this.head.current.style.transform = 'scale(0)'
        break;
      case 'body':
        avatar[e] = avatar[e] > 4 ? 1 : avatar[e]
        this.body.current.style.transform = 'scale(0)'
        break;
      case 'legs':
        avatar[e] = avatar[e] > 5 ? 1 : avatar[e]
        this.legs.current.style.transform = 'scale(0)'
        break;
      case 'back':
        avatar[e] = avatar[e] > 5 ? 1 : avatar[e]
        this.back.current.style.transform = 'scale(0)'
        break;
    }

    this.trans = setTimeout(() => {
      this.setState({avatar})
      this.head.current.style.transform = 'scale(1)'
      this.back.current.style.transform = 'scale(1)'
      this.body.current.style.transform = 'scale(1)'
      this.legs.current.style.transform = 'scale(1)'
    }, 80)
  }

  nextStep() {
    this.step1.current.classList.add('fadeout')
    this.createProfile()
  }

  createProfile(){
    var canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 300;

    let head = new Image()
    head.src = process.env.PUBLIC_URL + `/avatar/head/p${this.state.avatar.head}.png`
    head.onload = () => {
      let body = new Image()
      body.src = process.env.PUBLIC_URL + `/avatar/body/p${this.state.avatar.body}.png`
      body.onload = () => {
        let back = new Image()
        back.src = process.env.PUBLIC_URL + `/avatar/back/p${this.state.avatar.back}.png`

        back.onload = () => {
          var ctx = canvas.getContext("2d");
          ctx.drawImage(back, 0, 20, 300, 395);
          ctx.drawImage(head, 50, 45, 200, 195);
          ctx.drawImage(body, -85, 115, 470, 675);

          let data = canvas.toDataURL("image/png",0.7);
          setTimeout(() => this.props.getData(this.state.avatar,data),300)
        }

      }

    }

  }

  render() {
    return(
      <div>
      <style>{`

        .avatar-creation {
          position: fixed;
          left: 50vw;
          top: 50vh;
          transition: .3s;
          animation: fadein .5s 1;
        }
        .fadeout{
          opacity: 0;
          top: 30vh;
        }
        @keyframes fadein {
          0%   { top: 70vh; opacity:0; }
          100% { top: 50vh; opacity:1; }
        }
        .relative {
          position: relative;
        }
        .avatar-creation .title {
          font-size: 24px;
          position: fixed;
          left: 40px;
          top: 40px;
        }
        .avatar-creation .next {
          font-size: 24px;
          padding: 10px;
          position: fixed;
          right: 40px;
          bottom: 40px;
          border: 2px solid #000;
          display: inline-block;
          border-radius: 30px;
          cursor: pointer;
        }
        .avatar-creation .next:hover {
          background-color: #000;
          color: #fff;
        }

        .avatar {
          width: 300px;
          height: 600px;
          position: relative;
          transform: translate(-50%, -50%);
        }
        .avatar img {
          width: 100%;
        }
        .head {
          position: absolute;
          z-index: -1;
          width: 50%;
          left: 100px;
          top: 0px;
          transition: transform .08s
        }
        .body {
          position: absolute;
          z-index: 0;
          width: 100%;
          left: 21px;
          top: 90px;
          transition: transform .08s
        }
        .legs {
          position: absolute;
          z-index: -1;
          width: 100%;
          left: 25px;
          top: 250px;
          transition: transform .08s
        }
        .back {
          position: absolute;
          z-index: -2;
          width: 100%;
          left: 0;
          top: -20px;
          transition: transform .08s
        }

        .avatar-creation .btn {
          font-size: 24px;
          padding: 10px;
          border: 2px solid #000;
          display: inline-block;
          border-radius: 30px;
          position: absolute;
          cursor: pointer;
        }
        .avatar-creation .btn:hover {
          background-color: #000;
          color: #fff;
        }

        .headbtn {
          transform: translateX(-50%) translate(300px, -300px);
        }

        .bodybtn {
          transform: translateX(-50%) translate(-300px, -170px)
        }

        .legsbtn {
          transform: translateX(-50%) translate(300px, -30px)
        }

        .backgroundbtn {
          transform: translateX(-50%) translate(-300px, 100px)
        }

      `}</style>
        <div className='avatar-creation' ref={this.step1}>
          <div className='title'>create your avatar</div>
          <div className='next' onClick={() => this.nextStep()}>Next</div>
          <div className='relative'>
            <div className='btn headbtn' onClick={() => this.switch('head')}>Change Head</div>
            <div className='btn bodybtn' onClick={() => this.switch('body')}>Change Body</div>
            <div className='btn legsbtn' onClick={() => this.switch('legs')}>Change Legs</div>
            <div className='btn backgroundbtn' onClick={() => this.switch('back')}>Change Background</div>
          </div>
          <div className='avatar'>
            <div className='head' ref={this.head}><img className='asset-part' src={process.env.PUBLIC_URL + `/avatar/head/p${this.state.avatar.head}.png`} /></div>
            <div className='body' ref={this.body}><img className='asset-part' src={process.env.PUBLIC_URL + `/avatar/body/p${this.state.avatar.body}.png`} /></div>
            <div className='legs' ref={this.legs}><img className='asset-part' src={process.env.PUBLIC_URL + `/avatar/legs/p${this.state.avatar.legs}.png`} /></div>
            <div className='back' ref={this.back}><img className='asset-part' src={process.env.PUBLIC_URL + `/avatar/back/p${this.state.avatar.back}.png`} /></div>
          </div>
        </div>
      </div>
    )
  }
}

export default AvatarCreate
