import React from 'react';
import PropTypes from 'prop-types';
import asyncLoading from 'react-async-loader';
import isEqual from 'lodash.isequal';

import { move, socket } from '../../functions.js'
import Avatar from './avatar.js'
import Pin from './pin.js'

import mapStyle from './mapstyle.js'

import * as smith from '../../assets/smith.png'

class GoogleStreetview extends React.Component {
  constructor(start) {
    super(start);
    this.map = null
    this.streetView = null;
    this.avatars = []
    this.pins = []
    this.users = []
    this.allusers = []
    this.profiles = []
  }

  componentDidMount() {
    this.initialize(this.node, this.props);
  }

  componentDidUpdate(prevProps) {

    //update profile array
    //update new join
    if(prevProps.historyProfile !== this.props.historyProfile && prevProps.historyProfile.length === 0) {
      this.profiles = this.props.historyProfile
    }
    if(prevProps.newProfile !== this.props.newProfile) {
      this.profiles.push(this.props.newProfile)
    }

    this.initialize(this.node, prevProps);

    //update users by distance
    if(this.pos) {
      let users = []
      this.props.users.forEach(i => {
        if(Math.abs(i.data.lat - this.pos.lat) < .0008 && Math.abs(i.data.lng - this.pos.lng) < .0008){
          users.push(i)
          //console.log('in range',Math.abs(i.data.lat - this.pos.lat))
        } else {
          //console.log('out of range',Math.abs(i.data.lat - this.pos.lat))
        }
      })
      this.users = users
      this.allusers = this.props.users
    }

    //update avatars rendering
    if(this.streetView){

      this.avatars.forEach((i,index) => {
        let exist = -1
        this.users.forEach(j => {
          if(j.id === i.user.id) {
            exist *= -1
          }
        })
        if(exist < 0) {
          i.kill()
          this.avatars.splice(index,1)
        }
      })

      //add new user avatar and update old
      this.users.forEach(i => {

        let exist = -1
        this.avatars.forEach(j => {
          if(j.user.id === i.id) {
            //check if is new user
            exist *= -1
            j.update(i)
          }
        })
        if(exist < 0 && i.id !== socket.id) {
          let profile = null
          this.profiles.forEach(p => {
            if(p.id === i.id) {
              profile = p
            }
          })
          let chara = new Avatar(this.props.googleMaps, smith, this.streetView, i, profile)
          chara.init()
          this.avatars.push(chara)
        }
      })
    }


    if(this.map){

      this.pins.forEach((i,index) => {
        let exist = -1
        this.allusers.forEach(j => {
          if(j.id === i.user.id) {
            exist *= -1
          }
        })
        if(exist < 0) {
          i.kill()
          this.pins.splice(index,1)
        }
      })


      //add new user avatar and update old
      this.allusers.forEach(i => {


        let exist = -1

        this.pins.forEach(j => {
          if(j.user.id === i.id) {
            //check if is new user
            exist *= -1
            j.update(i)
          }
        })

        if(exist < 0 && i.id !== socket.id) {

          let profile = null
          this.profiles.forEach(p => {
            if(p.id === i.id) {
              profile = p
            }
          })
          let pin = new Pin(this.props.googleMaps, smith, this.map, i, profile)
          pin.init()
          this.pins.push(pin)

        }
      })
    }

    if(this.props.newPos !== prevProps.newPos && this.props.newPos !== null) {
      this.streetView.setPosition(this.props.newPos);
    }
  }

  componentWillUnmount() {
    if (this.streetView) {
      this.props.googleMaps.event.clearInstanceListeners(this.streetView);
    }
  }

  initialize(canvas, prevProps) {
    if (this.props.googleMaps && this.streetView == null) {
      //setup service
      var sv = new this.props.googleMaps.StreetViewService();


      //setup streetview
      this.streetView = new this.props.googleMaps.StreetViewPanorama(
        document.querySelector('.streetview'),
        this.props.streetViewPanoramaOptions,
      );

      //search in StreetViewService
      sv.getPanorama({
        location: this.props.streetViewPanoramaOptions.position,
        radius: 200,
        source: this.props.googleMaps.StreetViewSource.OUTDOOR
      }, (data, status) => {
        if (status === this.props.googleMaps.StreetViewStatus.OK) {
          this.streetView.setPano(data.location.pano);
        } else {
          console.error('Street View data not found for this location.');
        }
      });


      this.streetView.setPosition(this.props.streetViewPanoramaOptions.position)
      //setup map
      //stylemap
      this.map = new this.props.googleMaps.Map(
        document.querySelector('.map'),
        {
          mapTypeControl: false,
          fullscreenControl: false,
          center: this.props.streetViewPanoramaOptions.position,
          zoom: 14,
          styles: mapStyle
      });


      this.map.setStreetView(this.streetView);


      this.pov = this.streetView.getPov()

      //methods
      this.streetView.addListener('pano_changed', () => {
        if (this.props.onPanoChanged) {
          this.props.onPanoChanged(this.streetView.getPano());
        }
      });

      this.streetView.addListener('position_changed', () => {
        if (this.props.onPositionChanged) {
          let pos = {
            lat: this.streetView.getPosition().lat(),
            lng: this.streetView.getPosition().lng()
          }
          this.pos = pos
          move(pos)
          this.props.onPositionChanged(this.streetView.getPosition());
        }
      });

      this.streetView.addListener('pov_changed', () => {
        if (this.props.onPovChanged) {
          this.props.onPovChanged(this.streetView.getPov());
        }
      });

    }
    if (
      this.streetView !== null &&
      this.props.streetViewPanoramaOptions &&
      !isEqual(
        this.props.streetViewPanoramaOptions,
        prevProps.streetViewPanoramaOptions,
      )
    ) {
      const {
        zoom,
        pov,
        position,
        ...otherOptions
      } = this.props.streetViewPanoramaOptions;
      const {
        zoom: prevZoom,
        pov: prevPov,
        position: prevPos,
        ...prevOtherOptions
      } = prevProps.streetViewPanoramaOptions;
      if (!isEqual(zoom, prevZoom)) {
        this.streetView.setZoom(zoom);
      }
      if (!isEqual(pov, prevPov)) {
        this.streetView.setPov(pov);
      }
      if (!isEqual(position, prevPos)) {
        this.streetView.setPosition(position);
      }
      if (!isEqual(otherOptions, prevOtherOptions)) {
        this.streetView.setOptions(otherOptions);
      }
    }
  }

  render() {
    return <div style={{ width: '100vw', height: '100vh' }} ref={node => (this.node = node)} />;
  }
}

GoogleStreetview.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  apiKey: PropTypes.string,
  streetViewPanoramaOptions: PropTypes.object,
  onPositionChanged: PropTypes.func,
  onPovChanged: PropTypes.func,
  onPanoChanged: PropTypes.func,
  googleMaps: PropTypes.object,
};

GoogleStreetview.defaultProps = {
  apiKey: null,
  streetViewPanoramaOptions: {
    position: { lat: 40.7353688, lng: -73.9941869 },
    pov: { heading: 0, pitch: 0 },
    zoom: 1,
    //disable controls
    addressControl: false,
    fullscreenControl: false,
    zoomControl: false,
    scrollwheel: false,
    disableDefaultUI: true,
  },
  googleMaps: {},
  onPositionChanged: () => {},
  onPovChanged: () => {},
  onPanoChanged: () => {},
};

function mapScriptsToProps({ apiKey }) {
  if (!apiKey) return {};

  return {
    googleMaps: {
      globalPath: 'google.maps',
      url: `https://maps.googleapis.com/maps/api/js?key=${apiKey}`,
      jsonp: true,
    },
  };
}

export default asyncLoading(mapScriptsToProps)(GoogleStreetview);
