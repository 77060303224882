import React from 'react';
import './App.css';

//import component
import Map from './components/map.jsx'
import Onboarding from './components/onboarding.js'
import About from './components/about.jsx'


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      start: {},
      location: null,
      creating: false,
    }
  }

  componentDidUpdate() {
  }

  render() {
    return (
      <div className="App">
        <style>{`
            *:not(input) {
              user-select: none;
              -webkit-user-select: none;
              cursor: default;
            }
            body {
              font-family: tg;
            }
        `}</style>

      {
        this.state.stage === 1
        ? <Map start={this.state.start} location={this.state.location}/>
      : <Onboarding start={(start,location) => this.setState({stage:1, start:start, location: location})} creating={() => this.setState({creating:true})}/>
      }
      {
        this.state.stage === 1 || !this.state.creating
        ?<About />
        :''
      }
      </div>
    )
  }
}

export default App;
