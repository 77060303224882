import io from 'socket.io-client';

const devserver = 'http://localhost:8000'
const server = 'https://earthonline-server.herokuapp.com'

const socket = io(server,{transports: ['websocket'], upgrade: false});

function onboard(user, cb) {
  socket.on('render', users => cb(null, users));
  socket.emit('onboard', user);
}

function move(user) {
  socket.emit('move', user);
}

function send(msg) {
  socket.emit('send', msg);
}

export { onboard, move, socket, send }
