import React from 'react'

class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle:false,
    }
  }

  render() {
    return (
      <div>
        <style>{`
            a {
              color: inherit;
            }
            .aboutbtn {
              position: fixed;
              top: 20px;
              left: 20px;
              z-index: 99;
              cursor: pointer;
              padding: 3px 5px;
              background-color: #fff;
            }

            .closeabout {
              position: fixed;
              top: 30px;
              right: 30px;
              font-size: 32px;
              color: #fff;
            }

            .about-wrapper {
              background-color: #000;
              position: fixed;
              color: #fff;
              left: 0;
              top: 0;
              width: 100vw;
              height: 100vh;
              cursor: pointer;
              z-index: 999999;
              background-image: url(${process.env.PUBLIC_URL + `/avatar/world-r.jpg`});
              animation: bg 10s infinite;
              animation-timing-function: linear;
              transition: .3s;
              overflow: scroll;
            }

            @keyframes bg{
              from {background-position: 0px 0px;}
              to {background-position: 700px 638px;}
            }

            .about-body {
              font-size: 30px;
              padding: 50px;
            }

            .about-col {
              width: 33%;
              display: inline-block;
              vertical-align: top;
              box-sizing: border-box;
              line-height: 1.5em;
              padding-right: 50px;
            }

            .author {
              font-size: 20px;
            }



            @media only screen and (max-width: 900px) {
              .about-col {
                width: 100%;
                padding-right: 0px;
                margin-bottom: 120px;
                font-size: 20px;
              }
              .about-body {
                padding: 20px;
              }
            }

        `}</style>
      <div className='aboutbtn' onClick={() => this.setState({toggle:true})}>what is this?</div>
        {
          !this.state.toggle
          ?''
          :<div className='about-wrapper'>
            <div className='closeabout' onClick={() => this.setState({toggle:false})}>X</div>
            <div className='about-body'>
              <div className='about-col'>
                <span style={{fontSize: '40px',backgroundColor: '#000'}}>
                  Bring your friends and have a walk together in Japan, Iceland ... or any place on earth.
                </span>
              </div>
              <div className='about-col'>
                <span style={{backgroundColor: '#000'}}>
                  Gopeg is a chat room built based on google street view. Users can see each other in real context. It provide a experience of virtual tour robusted with multiple users communication.
                  <br/>
                  <br/>
                  While virtual tour becomes a big thing today, especially during this quarantine period. The experience is insufficient. The most virtual experience is built based on single user experience. In this pandemic, not only did we want to go outside, we urge to meet people out there. This is an experiment trying to discuss how can we respond to this problem.
                  <br/>
                  <br/>
                  It is very apt to utilize google street view into this project. As google street view provides the most comprehensive replication of outdoor space, we decided to build this multi-user virtual tour experience based on that. Thanks to google map's well-established API we craft the rendering smoothly.
                </span>
              </div>
              <div className='about-col author'>
                <span style={{backgroundColor: '#000'}}>

                This product is design and built by Yifu<br/>
                Check <a href='https://yifuzhang.xyz' target='_blank'>more interesting works</a> from him, <br/>or <a href='mailto:bluemanzhang@gmail.com?subject=Hello%20There!'>send him a email</a>

                <br/>
                <br/>

                Tech Stack:<br/>
                  React.js<br/>
                  Google Maps API<br/>
                  Socket.io<br/>
                <br/><br/>

                Image Licensing:<br/>
                Istock
                </span>
              </div>
            </div>
          </div>
        }

      </div>
    )
  }
}

export default About
