import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

class Location extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      location: '',
      error: '',
      latLng: null,
    }

    this.step2 = React.createRef()
  }

  nextStep() {

    geocodeByAddress(this.state.location)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({latLng})
        this.step2.current.classList.add('fadeout')
        setTimeout(() => this.props.getData(latLng),300)
      })
      .catch(error => {
        this.setState({error:'Sorry, we didnt find this position on earth!'})
      })

  }

  handleChange = (location) => {
    this.setState({ location:location, error:'' })
  }

  setPoi(pos) {
    this.step2.current.classList.add('fadeout')
    setTimeout(() => this.props.getData(pos),300)
  }

  componentDidMount() {
    let pos = []
    document.querySelectorAll('.poi').forEach((i,index) => {
      setTimeout(() => {
        let top = Math.sign(Math.random()-.5)*(Math.random()*30+10)+50
        let left = Math.sign(Math.random()-.5)*(Math.random()*30+10)+50
        if(pos) {
          while(checkClose([top,left])) {
            top = Math.sign(Math.random()-.5)*(Math.random()*30+10)+50
            left = Math.sign(Math.random()-.5)*(Math.random()*30+10)+50
          }
          pos.push([top,left])
        } else {
          pos.push([top,left])
        }

        i.style.left = left+'vw'
        i.style.top = top+'vh'
        i.style.opacity = 1
      }, index*100+300)
    })


    function checkClose(e) {
      let overlap = false
      pos.forEach(i => {
        if(Math.abs(e[0]-i[0])<3 && Math.abs(e[1]-i[1])<5) {
          overlap = true
        }
      })
      return overlap
    }
  }

  render() {
    return(
      <div>
      <style>{`

        .naming {
          position: fixed;
          left: 50vw;
          top: 50vh;
          transition: .3s;
          animation: fadein .5s 1;
        }
        .fadeout{
          opacity: 0;
          top: 30vh;
        }
        @keyframes fadein {
          0%   { top: 70vh; opacity:0; }
          100% { top: 50vh; opacity:1; }
        }
        .relative {
          position: relative;
        }


        .naming .name {
          position: fixed;
          border: none;
          border-bottom: 3px solid #000;
          font-size: 32px;
          padding: 20px 0;
          text-align: center;
          width: 500px;
          transform: translate(-50%, -50%);
          font-family: inherit !important;
          background-color: transparent;
        }
        .name:focus {
          outline: none;
        }

        .naming .title {
          font-size: 24px;
          position: fixed;
          left: 40px;
          top: 40px;
        }


        .naming .next {
          font-size: 24px;
          padding: 10px;
          position: absolute;
          left: 250px;
          transform: translateY(-50%);
          border: 2px solid #000;
          display: inline-block;
          border-radius: 30px;
          cursor: pointer;
        }
        .naming .next:hover {
          background-color: #000;
          color: #fff;
        }

        .suggest {
          position: fixed;
          font-size: 24px;
          text-align: left;
          width: 500px;
          transform: translate(-50%, 40px);
          background-color: transparent;
        }

        .error {
          position: fixed;
          width: 500px;
          transform: translate(-50%, -50%) translateY(-50px);
          text-align: center;
          color: #f00;
        }

        .poi {
          display: block;
          padding: 3px 6px;
          position: fixed;
          border: 2px solid;
          border-radius: 30px;
          font-size: 24px;
          background-color: #fff;
          cursor: pointer;
          opacity: 0;
          transition: opacity 1s;
          white-space: nowrap;
          transform: translateX(-50%);
        }

        .poi:hover {
          background-color: #000;
          color: #fff;
          z-index: 9;
        }

      `}</style>
        <div className='naming' ref={this.step2}>
          <div className='title'>Where do you want to go?</div>

          <div onClick={() => this.setPoi({lat:40.735314, lng:-73.994129})} className='poi'>Parsons School of Design</div>
          <div onClick={() => this.setPoi({lat:34.967984, lng:135.776442})} className='poi'>Fushimi Inari Taisha</div>
          <div onClick={() => this.setPoi({lat:51.518411, lng:-0.125733})} className='poi'>The British Museum</div>
          <div onClick={() => this.setPoi({lat:65.862185, lng:-23.464319})} className='poi'>Iceland</div>
          <div onClick={() => this.setPoi({lat:34.011266, lng:-118.500616})} className='poi'>Santa Monica</div>
          <div onClick={() => this.setPoi({lat:24.816804, lng:121.248600})} className='poi'>Taoyuan City</div>
          <div onClick={() => this.setPoi({lat:28.6098297, lng:-80.5995824})} className='poi'>Kennedy Space Center</div>






          <PlacesAutocomplete
            value={this.state.location}
            onChange={this.handleChange}
            onSelect={this.handleChange}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                <div className='error'>{this.state.error}</div>
                <input
                  {...getInputProps({
                    placeholder: 'I want to go to...',
                    className: 'name'
                  })}
                />
                <div className='next' onClick={() => this.nextStep()}>Go!!</div>
                <div className="suggest">
                  {suggestions.map(suggestion => {
                    const className = suggestion.active ? 'item-active' : 'item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                                ? { textOverflow: "ellipsis",  whiteSpace: "nowrap", overflow: "hidden", backgroundColor:'#ededed'}
                                : { textOverflow: "ellipsis",  whiteSpace: "nowrap", overflow: "hidden", backgroundColor:'white'};
                    return (
                      <div {...getSuggestionItemProps(suggestion, { className, style })}>
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>





        </div>
      </div>
    )
  }
}

export default Location
