import React from 'react'
import Upload from './upload.js'

import AvatarCreate from './avatarcreate.jsx'
import Naming from './name.jsx'
import Location from './location.jsx'

class Onboarding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profile:null,
      step: 0,
      avatar: null,
      name: '',
      location: {}
    }
    this.step0 = React.createRef()
    this.bg = React.createRef()
  }

  start() {
    let start = {profile: this.state.profile, name: this.state.name, avatar: this.state.avatar}
    this.props.start(start,this.state.location)
  }

  toStep1() {
    this.props.creating()
    this.step0.current.classList.add('fadeout')
    this.bg.current.style.opacity = 0
    setTimeout(() => this.setState({step:1}), 300)
  }

  renderStep() {
    switch(this.state.step) {
      case 0:
        return <div className='step0' ref={this.step0}>

            <div className='title'>
              <span style={{backgroundColor: 'white'}}>Gopeg</span>
              <div className='subtitle' >
                <span style={{backgroundColor: 'white'}}>A chatroom utilize Google Street View connect people together in virtual presence.</span>
              </div>
            </div>
            {
              window.innerWidth > 900
              ?<div className='btn start-m' onClick={() => this.toStep1()}>Start</div>
              :<div className='btn desktop'>Use this app on desktop, mobile version is under development</div>
            }

          </div>
        break;
      case 1:
        return <AvatarCreate getData={(e,p) => this.setState({avatar:e,profile:p,step:2})}/>
        break;
      case 2:
        return <Naming profile={this.state.profile} getData={(e) => this.setState({name:e,step:3})}/>
        break;
      case 3:
        return <Location getData={(e) => this.setState({location:e},() => this.start())}/>
        break;
      default:
        break;
    }
  }

  render() {
    return(
      <div>
      <style>{`

        .step0 {
          position: fixed;
          left: 50vw;
          top: 50vh;
          transform: translate(-50%, -50%);
          text-align: center;
          opacity: 1;
          transition: .3s;
        }
        .step0 .title {
          font-size: 32px;
        }

        @media only screen and (max-width: 900px) {
          .title {
            font-size: 20px !important;
          }

          .subtitle {
            margin: 0;
            font-size: 24px !important;
          }

          .step0 {
            top: ${window.innerHeight/2}px;
          }

          .btn {
            font-size: 24px !important;
            background-color: #000 !important;
            color: #fff !important;
          }
        }

        .fadeout{
          opacity: 0;
          top: 30vh;
        }
        .step0 .subtitle {
          margin: 40px 0;
          font-size: 40px;
        }

        .step0 .btn {
          font-size: 24px;
          padding: 10px;
          border: 2px solid #000;
          display: inline-block;
          cursor: pointer;
          border-radius: 30px;
          background-color: #fff;
        }

        .step0 .btn:hover {
          background-color: #000;
          color: #fff;
        }

        .introbg {
          width: 100vw;
          height: 100vh;
          position: fixed;
          left: 0;
          top: 0;
          object-fit: cover;
          background-image: url(${process.env.PUBLIC_URL + `/avatar/world.jpg`});
          animation: bg 10s infinite;
          animation-timing-function: linear;
          transition: .3s;
        }

        @keyframes bg{
          from {background-position: 0px 0px;}
          to {background-position: 700px 638px;}
        }

      `}</style>
      {
        this.state.step === 0 ?<div className='introbg' ref={this.bg}/>:''
      }

      {
        this.renderStep()
      }


      </div>
    )
  }
}

export default Onboarding
