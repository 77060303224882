class Overlay {
  constructor(googleMap, image, streetview, user, customhead) {
    // Initialize all properties.
    this.googleMap = googleMap
    this.image = image
    this.streetView = streetview
    this.user = user
    this.customhead = customhead
    this.avatar = null
    this.pin = null
  }

  init() {

    //create Avatar class and instance avatar
    Avatar.prototype = new this.googleMap.OverlayView();
    this.avatar = new Avatar(this.image, this.streetView, this.googleMap, this.user, this.customhead);

    function Avatar(image, streetview, googleMap, user, customhead) {

      // Initialize all properties.
      this.image_ = image;
      this.map_ = streetview;
      this.googleMap = googleMap
      this.anchorPos = {lat: Math.round(user.data.lat*10000000)/10000000, lng: Math.round(user.data.lng*10000000)/10000000}
      this.pos = new this.googleMap.LatLng(user.data.lat + Math.random()*0.00001, user.data.lng + Math.random()*0.00001);
      this.width = window.innerWidth
      this.height = window.innerHeight
      this.user = user
      this.customhead = customhead
      this.portrait = null

      window.addEventListener('resize', () => {
        this.width = window.innerWidth
        this.height = window.innerHeight
      })
      // Define a property to hold the image's div. We'll
      // actually create this div upon receipt of the onAdd()
      // method so we'll leave it null for now.
      this.div_ = null;
      // Explicitly call setMap on this overlay.
      this.setMap(streetview);
    }

    //add dom
    Avatar.prototype.onAdd = function() {

      var div = document.createElement('div');
      div.style.borderStyle = 'none';
      div.style.borderWidth = '0px';
      div.style.position = 'absolute';
      div.style.textAlign = 'center'

      var wrapper = document.createElement('div');
      wrapper.style.position = 'relative'
      wrapper.style.width = '100%'
      wrapper.style.height = '100%';
      div.appendChild(wrapper);

      //create name tag
      if(this.user.data.name) {
        var nametag = document.createElement('span')
        nametag.innerHTML = this.user.data.name
        nametag.style.position = 'absolute'
        nametag.style.bottom = '145%'
        nametag.style.left = '50%'
        nametag.style.fontSize = '20px'
        nametag.style.padding = '5px'
        nametag.style.border = '2px solid #000'
        nametag.style.borderRadius = '10px'
        nametag.style.backgroundColor = '#fff'
        nametag.style.display = 'inline-block'
        nametag.style.transform = 'translateX(-50%)'
        nametag.style.whiteSpace = 'nowrap'
        wrapper.appendChild(nametag);
      }

      // Create the img element and attach it to the div.
      var back = new Image();
      back.src = process.env.PUBLIC_URL + `/avatar/back/p${this.user.data.avatar.back}.png`
      back.style.width = '100%';
      back.style.left = '0';
      back.style.bottom = '83%';
      back.style.position = 'absolute';

      var head = new Image();
      head.src = process.env.PUBLIC_URL + `/avatar/head/p${this.user.data.avatar.head}.png`
      head.style.width = '50%';
      head.style.left = '25%';
      head.style.bottom = '117%';
      head.style.position = 'absolute';

      var body = new Image();
      body.src = process.env.PUBLIC_URL + `/avatar/body/p${this.user.data.avatar.body}.png`
      body.style.width = '100%';
      body.style.left = '0';
      body.style.bottom = '72%';
      body.style.position = 'absolute';

      var legs = new Image();
      legs.src = process.env.PUBLIC_URL + `/avatar/legs/p${this.user.data.avatar.legs}.png`
      legs.style.width = '100%';
      legs.style.left = '0';
      legs.bottom = '0';
      legs.style.position = 'absolute';

      wrapper.appendChild(back);
      wrapper.appendChild(head);
      wrapper.appendChild(legs);
      wrapper.appendChild(body);

      this.div_ = div;

      // Add the element to the "overlayLayer" pane.
      var panes = this.getPanes();
      panes.overlayLayer.appendChild(div);
    };

    //draw image
    Avatar.prototype.draw = function() {

        // Resize the image's div to fit the indicated dimensions.

        let cameraPos = this.map_.getPosition()
        let cameraPov = this.map_.getPov()

        this.heading = this.googleMap.geometry.spherical.computeHeading(cameraPos, this.pos)
        this.distance = this.googleMap.geometry.spherical.computeDistanceBetween(cameraPos, this.pos);

        this.dHeading = normalizeAngle(this.heading - cameraPov.heading)/90

        //camera height
        var height = 3

        var scale = this.distance < .1 ? 100000000 : .5*window.innerWidth / this.distance
        var dx = (this.dHeading*.9 + .5) * this.width
        var pitchRange = 90*this.height/this.width
        var dPitch = -(-Math.atan(height/this.distance)*180/Math.PI - cameraPov.pitch)*.9
        var dy = dPitch/pitchRange*this.height + this.height/2
        var h = 2 * scale < this.height ? 2 * scale : this.height
        var w = h/2

        let div = this.div_;
        div.style.left = dx - w/2 +'px';
        div.style.top = dy - h + 'px';
        div.style.width = w + 'px';
        div.style.height = h + 'px';
        div.style.zIndex = -Math.ceil(this.distance)

        if(this.portrait) {
          this.portrait.style.width = w/2 + 'px'
          this.portrait.style.height = w/2 + 'px'
          this.portrait.style.left = w/4 + 'px'
        }

    };

    Avatar.prototype.onRemove = function() {
      this.div_.parentNode.removeChild(this.div_);
      this.div_ = null;
    };

  }

  update(user) {
    //check if the user has moved
    let same = this.avatar.anchorPos.lat===Math.round(user.data.lat*10000000)/10000000 && this.avatar.anchorPos.lng===Math.round(user.data.lng*10000000)/10000000
    if(!same) {
      //move user: update user pos
      this.avatar.anchorPos = {lat: Math.round(user.data.lat*10000000)/10000000, lng: Math.round(user.data.lng*10000000)/10000000}
      this.avatar.pos = new this.googleMap.LatLng(user.data.lat + Math.random()*0.00001, user.data.lng + Math.random()*0.00001);
      if(this.avatar.div_) {
        this.avatar.div_.style.transitionDuration = '1s'
        if(this.avatar.portrait){
          this.avatar.portrait.style.transitionDuration = '1s'
        }
        this.avatar.draw()
        setTimeout(() => {
          if(this.avatar.div_){
            this.avatar.div_.style.transitionDuration = '0s'
            if(this.avatar.portrait){
              this.avatar.portrait.style.transitionDuration = '0s'
            }
          }
        }, 1000)
      }
      //move pin: update user pos
    } else {
      //console.log('didnt move')
    }
  }

  kill() {
    this.avatar.setMap(null);
  }

}

function normalizeAngle(a)
{

    while (a > 180)
    {
        a -= 360;
    }

    while (a < -180)
    {
        a += 360;
    }

    return a;
}


export default Overlay
