class Overlay {
  constructor(googleMap, image, map, user) {
    // Initialize all properties.
    this.googleMap = googleMap
    this.image = image
    this.map = map
    this.user = user
    this.avatar = null
    this.pin = null
  }

  init() {

    //marker in map
    Pin.prototype = new this.googleMap.OverlayView();
    this.pin = new Pin(this.image, this.map, this.googleMap, this.user);
    function Pin(image, map, googleMap, user) {

      // Initialize all properties.
      this.image_ = image;
      this.map_ = map;
      this.googleMap = googleMap
      this.anchorPos = {lat: Math.round(user.data.lat*10000000)/10000000, lng: Math.round(user.data.lng*10000000)/10000000}
      this.pos = new this.googleMap.LatLng(user.data.lat + Math.random()*0.00001, user.data.lng + Math.random()*0.00001);
      this.width = window.innerWidth
      this.height = window.innerHeight
      this.user = user

      window.addEventListener('resize', () => {
        this.width = window.innerWidth
        this.height = window.innerHeight
      })
      // Define a property to hold the image's div. We'll
      // actually create this div upon receipt of the onAdd()
      // method so we'll leave it null for now.
      this.div_ = null;
      // Explicitly call setMap on this overlay.
      this.setMap(map);
    }

    //add dom
    Pin.prototype.onAdd = function() {

      var div = document.createElement('div');
      div.style.position = 'absolute';


      var wrapper = document.createElement('div');
      wrapper.style.position = 'relative'
      div.appendChild(wrapper);

      //create name tag
      var nametag = document.createElement('span')
      nametag.innerHTML = this.user.data.name || 'John Doe'
      nametag.style.fontSize = '14px'
      nametag.style.padding = '4px'
      nametag.style.borderRadius = '2px'
      nametag.style.backgroundColor = '#fff'
      nametag.style.display = 'block'
      nametag.style.position = 'absolute'
      nametag.style.bottom = '45px'
      nametag.style.left = '0px'
      nametag.style.transform = 'translateX(-50%)'
      nametag.style.whiteSpace = 'nowrap'
      nametag.style.boxShadow = '2px 2px 5px rgba(0,0,0,.3)'
      wrapper.appendChild(nametag);

      let peg = new Image();
      peg.src = process.env.PUBLIC_URL + `/avatar/pegman.png`;
      peg.style.width = '16px'
      peg.style.position = 'absolute'
      peg.style.zIndex = '1'
      peg.style.bottom = 0
      peg.style.left = '-8px'
      wrapper.appendChild(peg)

      this.div_ = div;

      // Add the element to the "overlayLayer" pane.
      var panes = this.getPanes();
      panes.overlayLayer.appendChild(div);
    };

    Pin.prototype.draw = function() {

        let overlayProjection = this.getProjection();

        if(overlayProjection && this.div_){

          var position = overlayProjection.fromLatLngToDivPixel(this.pos);

          let div = this.div_;
          div.style.left = position.x +'px';
          div.style.top = position.y + 'px';
          div.style.width = '50px';
          div.style.height = '50px';
        }

    };

    Pin.prototype.onRemove = function() {
      this.div_.parentNode.removeChild(this.div_);
      this.div_ = null;
    };

  }

  update(user) {
    //check if the user has moved
    let same = this.pin.anchorPos.lat===Math.round(user.data.lat*10000000)/10000000 && this.pin.anchorPos.lng===Math.round(user.data.lng*10000000)/10000000
    this.pin.anchorPos = {lat: Math.round(user.data.lat*10000000)/10000000, lng: Math.round(user.data.lng*10000000)/10000000}
    this.pin.pos = new this.googleMap.LatLng(user.data.lat + Math.random()*0.00001, user.data.lng + Math.random()*0.00001);
    if(!same) {
      this.pin.draw()
      //move pin: update user pos
    } else {
      //console.log('didnt move')
    }
  }

  kill() {
    this.pin.setMap(null);
  }

}


export default Overlay
