import React from 'react'

class Upload extends React.Component {
  componentDidMount() {

    let dropzone = document.querySelector('.dropzone')

    //prevent default
    let eventNames = ['dragenter', 'dragover', 'dragleave', 'drop']
    eventNames.forEach(e => {
      dropzone.addEventListener(e, preventDefault, false)
    })

    function preventDefault(e) {
      e.preventDefault()
      e.stopPropagation()
    }

    //handle drop
    dropzone.addEventListener('drop', (e) => this.itemDrop(e))
  }

  itemDrop(e) {

    let dt = e.dataTransfer
    let file = dt.files[0]
    if( !( /image/i ).test( file.type ) ) {
      document.querySelector('.error').style.display = 'block'
      document.querySelector('.error_text').innerHTML = 'please upload a valid image file!'
      return false;
    }

    document.querySelector('.error').style.display = 'none'

    //preview
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      // blob stuff
      var blob = new Blob([e.target.result]); // create blob...
      window.URL = window.URL || window.webkitURL;
      var blobURL = window.URL.createObjectURL(blob); // and get it's URL

      // helper Image object
      let preview = document.querySelector('.preview')
      preview.innerHTML = ''
      var image = new Image();
      image.src = blobURL;


      image.onload = () => {
        // have to wait till it's loaded
        var resized = this.resizeMe(image); // send it to canvas

        let previewImg = new Image();
        previewImg.src = resized;
        previewImg.style.width = '200px'
        previewImg.style.height = '200px'
        previewImg.style.objectFit = 'cover'
        previewImg.style.borderRadius = '50%'
        preview.appendChild(previewImg); // preview commented out, I am using the canvas instead

        this.props.update(resized)
        //do some thing to resized
      }
    };
  }




  resizeMe(img) {

    var canvas = document.createElement('canvas');

    var width = img.width;
    var height = img.height;

    console.log(width,height)

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if(height > 100) {
        width = Math.round(width *= 100 / height);
        height = 100
      }
    } else {
      if (width > 100) {
        height = Math.round(height *= 100 / width);
        width = 100;
      }
    }

    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    return canvas.toDataURL("image/jpeg",0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)

  }

  render() {
    return(
      <div className='dropzone' style={{position: 'relative'}}>
      <style>{`
        .tooltip {
          position: absolute;
          left: 0;
          top: 0;
          width: 200px;
          height: 200px;
          display: flex;
          align-items: center;
        }
        `}</style>
        <div className='preview'></div>
        <div className='tooltip'>drop profile img here(optional)</div>
      </div>
    )
  }
}

export default Upload
